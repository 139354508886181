$breakpoint-more-height: 700px;
$margin-top-more-height: 100px;

$border-color: #a9aa9c;
$input-color: #8a8d7c;
$input-bg: #f1f0dc;

$input-padding-x: 8px;
$input-padding-y: 8px;

$parkbank-max-width: 500px;

* {
  box-sizing: border-box;
}

html, body, .app {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  height: 100%;
}

input {
  font-size: 16px;
}

input:focus,
button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(138, 141, 124, 0.25);
}

input,
button {
  /* Combining ::selection and ::-moz-selection WILL NOT WORK */
  &::selection {
    background: rgba(138, 141, 124, 0.25);
    color: $input-color;
  }
  &::-moz-selection {
    background: rgba(138, 141, 124, 0.25);
    color: $input-color;
  }

}

.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parkbank-control {
  max-width: $parkbank-max-width;
  width: 100%;
  padding: 15px;

  @media screen and (min-height: $breakpoint-more-height) {
    margin-top: $margin-top-more-height;
  }

  .parkbank-input {
    display: flex;
    align-items: center;

    .react-autosuggest__container {
      flex: 1 1;
      position: relative;
    }

    .react-autosuggest__suggestions-container--open {
      position: absolute;
      border: 1px solid $border-color;
      background: #fff;
      left: 0;
      right: 0;
      margin-top: -1px;
      max-height: calc(275px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      ul, li {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      .react-autosuggest__suggestion {
        padding: 5px $input-padding-x;
        cursor: pointer;
        color: $border-color;
      }

      .react-autosuggest__suggestion--highlighted {
        background: $input-bg;
        color: $input-color;
      }
    }
  }

  .parkbank-info,
  .react-autosuggest__input {
    width: 100%;
    border: 1px solid $border-color;
    padding: $input-padding-y $input-padding-x;
    background: $input-bg;
    color: $input-color;
    font-weight: 300;
    box-shadow: none;
    border-radius: 0;
    background-clip: padding-box;

  }

  .info-link {
    a {
      display: inline-block;
      margin-top: 5px;
      font-size: 14px;
      color: $border-color;
      word-break: break-all;

      &:hover {
        color: darken($border-color, 20%);
      }
    }
  }

  button {
    border-style: none;
    background: transparent no-repeat url('../images/ip.png') center center;
    background-size: contain;
    background-origin: content-box;
    padding: 6px;
    margin-left: 3px;
    width: 40px;
    height: 32px;
    text-indent: -30000em;
  }
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  figure {
    margin: 0;
    text-align: center;

    img {
      max-width: 100%;
    }

    figcaption {
      margin-top: 10px;
    }
  }

  .centered-image {
    max-width: calc(100% - 30px);
  }
}